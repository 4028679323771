/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: Font-face
	Author: Shane Prendergast
	Author URL: http://www.webknit.co.uk
	Template URL:h ttp://base.webknit.co.uk/
*/


/* - CONTENTS
-------------------------------------------------
	- Font Face  ........................... 1.0.
		- Name of Font ..................... 1.1.
	
*/


/*********************************************
	- 1.0 -  MIXINS
*********************************************/
	
	/* - 1.1. - Name of Font
	------------------------------------------ */

	@font-face {
	    font-family: 'NAME OF FONT';
	    src: url('assets/fonts/NAME OF FONT.eot');
	    src: url('assets/fonts/NAME OF FONT.eot?#iefix') format('embedded-opentype'),
	         url('assets/fonts/NAME OF FONT.woff') format('woff'),
	         url('assets/fonts/NAME OF FONT.ttf') format('truetype'),
	         url('assets/fonts/NAME OF FONT.svg#wisdom_script_airegular') format('svg');
	    font-weight: normal;
	    font-style: normal;
	
	}