/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: Buttons
	Author: Shane Prendergast
	Author URL: http://www.webknit.co.uk
	Template URL: http://base.webknit.co.uk/
*/


/* - CONTENTS
-------------------------------------------------
	- Buttons .............................. 1.0.
*/


/*********************************************
	- 1.0 -  BUTTONS
*********************************************/

	.btn {
		background: transparent;
		border: 3px solid $col-standard;
	    color: $col-standard;
	    cursor: pointer;
	    display: inline-block;
	    float: left;
	    font-family: 'Lato', sans-serif;
	    letter-spacing: 0.09rem;
	    margin-bottom: 1rem;
	    outline: 0 none;
	    padding: 0.9rem 1.2rem;
	    text-align: center;
	    text-transform: uppercase;
	    width: auto;
	    &:hover {
	    	background: $col-standard;
			color: $col-white;
			text-decoration: none;
		}
		&--white {
	    	border-color: $col-white;
	    	color: $col-white;
	    	&:hover {
		    	background: $col-white;
				color: $col-standard;
			}
	    }
	}
	
	a.button-full-width {
		padding-left: 0 !important;
		padding-right: 0 !important;
		text-align: center; 
		width: 100%;
	}
	
	/* Fix for odd Mozilla border & padding issues */
	button::-moz-focus-inner,
	input::-moz-focus-inner {
	    border: 0;
	    padding: 0;
	}
