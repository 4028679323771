/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: Example SASS
	Author: Shane Prendergast
	Author URL: http://www.webknit.co.uk
	Template URL:h ttp://base.webknit.co.uk/
*/


/* - CONTENTS
-------------------------------------------------
	- Example  ............................. 1.0.
	
*/


/*********************************************
	- 1.0 -  EXAMPLE
*********************************************/

	.nav {
		background-color: $col-standard;
		display: none;
		height: 100%;
		left: 0;
		position: fixed;
		top: 0;
		width: 100%;
		&__close {
			cursor: pointer;
			position: absolute;
			right: 30px;
			top: 30px;
			max-width: 20px;
		}
		&__box {
			left: 50%;
			max-width: 80%;
			position: absolute;
			top: 45%;
			transform: translate(-50%, -50%);
			width: 600px;
		}
		&__logo {
			margin-bottom: 2rem;
			max-width: 150px;
			@include breakpoint(large) {
				margin-bottom: 2rem;
				max-width: 250px;
			}
		}
		li {
			@include breakpoint(medium) {
				display: inline-block;
				font-size: 1.5rem;
				width: 48%;
			}
		}
		li, a {
			color: $col-white;
		}
		a {
			&:hover {
				text-decoration: underline;
			}
		}
	}