/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: Example SASS
	Author: Shane Prendergast
	Author URL: http://www.webknit.co.uk
	Template URL:h ttp://base.webknit.co.uk/
*/


/* - CONTENTS
-------------------------------------------------
	- Example  ............................. 1.0.
	
*/


/*********************************************
	- 1.0 -  EXAMPLE
*********************************************/

	.header {
		border-bottom: 2px solid $col-standard;
		padding: 2rem 0;
		&__logo {
			left: 0;
			margin: 0 auto;
			max-width: 240px;
			position: absolute;
			right: 0;
			top: 116px;
		    @include breakpoint(medium) {
		    	max-width: 300px;
		    	top: 55px;
		    }
		}
		&__btn {
			float: right;
		}
		&__menu-btn {
			border: none;
			float: left;
			&:hover {
				span {
					background-color: $col-white;
				}
			}
		}
		&__menu-bars {
			float: left;
			margin-right: 0.7rem;
			position: relative;
			top: 3px;
			span {
				background-color: $col-standard;
				display: block;
				height: 3px;
				width: 20px;
				&:first-child {
					margin-bottom: 10px;
				}
			}
		}
		&__menu-bars {
			
		}
	}

	#google_translate_element {
		.home & {
			left: 20px;
			position: absolute;
			top: 100px;
			@include breakpoint(medium) {
				left: 0;
				margin: 0 auto;
				right: 0;
				top: 50px;
				width: 158px;
			}
		}
	}