/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: Example SASS
	Author: Shane Prendergast
	Author URL: http://www.webknit.co.uk
	Template URL:h ttp://base.webknit.co.uk/
*/


/* - CONTENTS
-------------------------------------------------
	- Example  ............................. 1.0.
	
*/


/*********************************************
	- 1.0 -  EXAMPLE
*********************************************/

	.popup {
		background-color: rgba(0,0,0,0.6);
		display: none;
		height: 100%;
		position: fixed;
		top: 0;
		width: 100%;
		&__img {
			border: 10px solid white;
			left: 50%;
			max-width: 800px;
			position: fixed;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 90%;
			z-index: 1;
			@include breakpoint(medium) {
				border: 30px solid white;
			}
		}
	}