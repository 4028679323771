/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: Reset
	Author: Shane Prendergast
	Author URL: http://www.webknit.co.uk
	Template URL: http://base.webknit.co.uk/
/*


/* - CONTENTS
-------------------------------------------------
	- Reset ................................ 1.0.
	- HTML5 Reset .......................... 2.0.
*/


/*********************************************
	- 1.0 -  RESET
*********************************************/

	body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote,th,td{margin:0;padding:0;}table{border-collapse:collapse;border-spacing:0;}fieldset,img{border:0;}address,caption,cite,code,dfn,th,var{font-style:normal;font-weight:normal;}ol,ul{list-style:none;}caption,th{text-align:left;}h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal;}q:before,q:after{content:'';}abbr,acronym{border:0;}
	
	
/*********************************************
	- 2.0 -  HTML5 RESET
*********************************************/

	article, aside, dialog, figure, footer, header, hgroup, nav, section {
		display: block; 
		zoom: 1;
	}
	
	audio, canvas, video { 
		display: inline-block; *display: inline; *zoom: 1; 
	}