/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: Example SASS
	Author: Shane Prendergast
	Author URL: http://www.webknit.co.uk
	Template URL:h ttp://base.webknit.co.uk/
*/


/* - CONTENTS
-------------------------------------------------
	- Example  ............................. 1.0.
	
*/


/*********************************************
	- 1.0 -  EXAMPLE
*********************************************/

	.box {
		position: relative;
		vertical-align: bottom;
		&:after {
			background-color: rgba(0,0,0,0.2);
			content: "";
			display: block;
			height: 100%;
			position: absolute;
			top: 0;
			transition: background-color 0.2s ease-in-out;
			width: 100%;
		}
		&:hover {
			&:after {
				background-color: rgba(0,0,0,0.8);
			}
		}
		&--home-1,
		&--home-2,
		&--home-3 {
			clear: both;
			@include breakpoint(large) {
				max-width: 500px;
			}
		}
		&--home-2 {
			@include breakpoint(large) {
				float: right;
				right: -50px;
				top: -50px;
			}
		}
		&--home-3 {
			@include breakpoint(large) {
				left: -80px;
				top: -100px;
			}
		}
		&__img {

		}
		&__btn {
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			z-index: 1;
		}
	}
	
	