/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: Base/Main CSS styles
	Author: Shane Prendergast
	Author URL: http://www.webknit.co.uk
	Template URL: http://base.webknit.co.uk/
	Notes: 
		Base CSS styles and Mobile first approach. 
*/


/* - CONTENTS
-------------------------------------------------
	- SASS modules ......................... 1.0.
    	- Variables ........................ 1.1.
    	- Functions .........................1.2.
    	- Mixins ............................1.3.
    - CSS Reset ............................ 2.0.
    	- Main reset ........................2.1.
    - SASS partials ........................ 3.0.
    	- Vendor ............................3.1.
    	- Base ............................. 3.2.
    	- Buttons ...........................3.3.
    	- Forms ............................ 3.4.
    - Grids ................................ 4.0.
    - Example section ...................... 5.0.
    - @font-face ........................... 0.0.
*/


/*********************************************
	- 1.0 -  SASS MODULES
	The modules directory is reserved for Sass code that doesn't cause Sass to actually output CSS. 
	Things like mixin declarations, functions, and variables.
*********************************************/

	/* - 1.1. - COLOURS, GRADIENTS, TYPOGRAPHY
	------------------------------------------ */
	
	@import "modules/_variables";


	/* - 1.2. - FUNCTIONS
	------------------------------------------ */

	@import "modules/_functions";
	
	
	/* - 1.3. - MIXINS
	------------------------------------------ */

	@import "modules/_mixins";
	
	
/*********************************************
	- 2.0 -  CSS RESET
*********************************************/

	/* - 2.1. - RESET INCLUDING HTML5
	------------------------------------------ */
	
	@import "partials/base/_reset";
	

/*********************************************
	- 3.0 -  SASS PARTIALS
*********************************************/

	/* - 3.1. - VENDOR
	------------------------------------------ */

	@import "partials/base/_vendor";
	
	
	/* - 3.2. - BASE ELEMENTS, STRUCTURE, TYPOGRAPHY, LINKS
	------------------------------------------ */
	
	@import "partials/base/_base";
	
	
	/* - 3.3. - BUTTONS 
	------------------------------------------ */
	
	@import "partials/base/_buttons";
	
	
	/* - 3.4. - FORMS 
	------------------------------------------ */
	
	@import "partials/base/_forms";


/*********************************************
	- 4.0 -  GRIDS
*********************************************/

	@import "partials/base/_grid";


/*********************************************
	- 5.0 -  COMPONENTS
*********************************************/
@import "partials/components/_banner.scss";
@import "partials/components/_box.scss";
@import "partials/components/_footer.scss";
@import "partials/components/_header.scss";
@import "partials/components/_nav.scss";
@import "partials/components/_popup.scss";
@import "partials/components/_section.scss";
@import "partials/components/_slider.scss";

/*********************************************
	- 0.0 -  @FONT-FACE
*********************************************/
	
	@import "partials/base/_font-face";
	




		

















