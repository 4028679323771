/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: Example SASS
	Author: Shane Prendergast
	Author URL: http://www.webknit.co.uk
	Template URL:h ttp://base.webknit.co.uk/
*/


/* - CONTENTS
-------------------------------------------------
	- Example  ............................. 1.0.
	
*/


/*********************************************
	- 1.0 -  EXAMPLE
*********************************************/

	.carousel-cell {
		background: #fff;
		counter-increment: gallery-cell;
		margin-right: 50px;
		opacity: 0.2;
		padding: 0.5rem;
		transition: 0.2s ease-in-out opacity;
		width: 80%;
		@include breakpoint(medium) {
			font-size: 1rem;
			padding: 2rem;
			width: 50%;
		}
		p {
			font-size: 0.8rem;
			@include breakpoint(medium) {
				font-size: 1.1rem;
			}
		}
		&.is-selected {
			opacity: 1;
		}
	}