/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: Example SASS
	Author: Shane Prendergast
	Author URL: http://www.webknit.co.uk
	Template URL:h ttp://base.webknit.co.uk/
*/


/* - CONTENTS
-------------------------------------------------
	- Example  ............................. 1.0.
	
*/


/*********************************************
	- 1.0 -  EXAMPLE
*********************************************/

	.section {
		padding: 1.5rem 0;
		position: relative;
		@include breakpoint(medium) {
			padding: 3rem 0;
		}
		@include breakpoint(large) {
			padding: 5rem 0;
		}
		&--grey {
			background-color: #F4F4F4;
		}
		&--info {
			//background-color: #f9f9f9;
			// .section__head {
			// 	border-color: white;
			// }
			// h2, p, .textwidget {
			// 	color: white;
			// }
		}
		&--grey-gradients {
			&:after,
			&:before {
				content: "";
				display: block;
				height: 50%;
				position: absolute;
				width: 100%;
			}
			&:before {
				background-color: #E2E2E2;
				top: 0;
			}
			&:after {
				background-color: #F4F4F4;
				bottom: 0;
			}
		}
		&__head {
			border-bottom: 3px solid $col-standard;
			display: inline-block;
			margin-bottom: 3rem;
			@include breakpoint(medium) {
				margin-bottom: 5rem;
			}
		}
		&__center-text {
			margin: 0 auto;
			max-width: 800px;
			text-align: center;
			width: 80%;
		}
		&__content {
			position: relative;
			z-index: 1;
			&--page {
				margin: 0 auto;
				max-width: 700px;
				width: 95%;
			}
		}
		&__flush-img {
			margin-left: 0;
			padding-left: 0;
		}
		&__list {
			display: flex;
			flex-flow: row wrap;
			justify-content: space-between;
		}
		&__list-item {
			margin-bottom: 1.5rem;
			@include breakpoint(medium) {
				width: 45%;
			}
			strong {
				font-family: 'Lato', sans-serif;
			}
		}
	}