/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: Forms
	Author: Shane Prendergast
	Author URL: http://www.webknit.co.uk
	Template URL: http://base.webknit.co.uk/
*/


/* - CONTENTS
-------------------------------------------------
	- Forms ................................ 1.0.
		- Webkit reset ..................... 1.1.
		- Base Elements .................... 1.2.
	- Example Form ......................... 2.0.
*/


/*********************************************
	- 1.0 -  FORMS
*********************************************/

	/* - 1.1. - WEBKIT RESET
	------------------------------------------ */

	input[type=text],
	input[type=email],
	input[type=tel],
	input[type=url],
	input[type=search],
	input[type=submit] {
		appearance: none;
		border-radius: 0;
	}

	/* - 1.2. - BASE ELEMENTS
	------------------------------------------ */

	input, 
	textarea {
		border: none;
		border-radius: 0.125rem;
		display: block;
		font-family: $base-font-family;
		font-size: $base-font-size;
		line-height: 1.4;
		margin: 0;
		margin-bottom: 1.250rem;
		outline: none;
		padding: 1rem;
		width: 100%;
	}
	
	.input[type=email] {
		
	}
	
	input {
		
	}
	
	textarea {
		min-height: 300px;
	}
	
	input[type=submit] {
		background: transparent;
		border: 3px solid $col-standard;
	    color: $col-standard;
	    cursor: pointer;
	    display: inline-block;
	    //float: left;
	    font-family: 'Lato', sans-serif;
	    letter-spacing: 0.09rem;
	    margin-bottom: 1rem;
	    outline: 0 none;
	    padding: 0.9rem 1.2rem;
	    text-align: center;
	    text-transform: uppercase;
	    width: auto;
	    &:hover {
	    	background: $col-standard;
			color: $col-white;
			text-decoration: none;
		}
	}

	input[type=checkbox] {
		width: auto;
	}

	label {
		color: #95989A;
		display: block;
		font-family: 'Lato', sans-serif;
		font-size: 0.8rem;
		font-weight: normal;
		margin-bottom: 0.5rem;
		text-transform: uppercase;
	}

	.wpcf7-form-control {
		display: inline-block;
	}

	.wpcf7-form-control-wrap {
	    position: relative;
	    clear: both;
	    text-align: center;
	    margin-bottom: 2rem;
	}
	

/*********************************************
	- 2.0 -  EXAMPLE FORM
*********************************************/