/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: Banner
	Author: Shane Prendergast
	Author URL: http://www.webknit.co.uk
	Template URL:h ttp://base.webknit.co.uk/
*/


/* - CONTENTS
-------------------------------------------------
	- Example  ............................. 1.0.
	
*/


/*********************************************
	- 1.0 -  EXAMPLE
*********************************************/
	
	.banner {
		background-image: url(../img/banner.png);
		background-position: center;
		background-size: cover;
		height: 100%;
		overflow: hidden;
		position: relative;
		width: 100%;
		&:after {
			background: $col-white;
			backface-visibility: hidden;
			bottom: -34px;
			content: '';
			height: 65px;
			left: 0;
			position: absolute;
			transform: skewY(-2.5deg);
			width: 100%;
		}
		&:before {
			background-color: rgba(0,0,0,0.5);
			content: "";
			display: block;
			height: 100%;
			width: 100%;
		}
		&--page {
			background-image: none;
			background-color: $col-standard;
			height: 400px;
			@include breakpoint(large) {
				//height: 500px;
			}
			&:before {
				//display: none;
			}
		}
		&--img {
			background-image: url(../img/banner.png);
			background-position: center;
			background-size: cover;
			&:before {
				background-color: rgba(0,0,0,0.5);
				content: "";
				display: block;
				height: 100%;
				width: 100%;
			}
		}
		&__head {
			color: $col-white;
			font-size: 2rem;
			left: 10%;
			margin: 0;
			position: absolute;
			top: calc(50% - 30px);
			transform: translateY(-50%);
			@include breakpoint(medium) {
				font-size: 5rem;
			}
		}
		&__menu-btn, &__btn {
			position: absolute;
			top: 30px;
		}
		&__btn {
			right: 30px;
		}
		&__menu-btn {
			border: none;
			left: 30px;
			&:hover {
				span {
					background-color: $col-standard;
				}
			}
		}
		&__menu-bars {
			float: left;
			margin-right: 0.7rem;
			position: relative;
			top: 3px;
			span {
				background-color: $col-white;
				display: block;
				height: 3px;
				width: 20px;
				&:first-child {
					margin-bottom: 10px;
				}
			}
		}
		&__box {
			left: 50%;
			max-width: 80%;
			position: absolute;
			text-align: center;
			top: 45%;
			transform: translate(-50%, -50%);
			width: 600px;
			img {
				width: 500px;
			}
		}
		&__text {
			color: $col-white;
			font-family: $header-font-family;
			text-align: center;
			@include breakpoint(medium) {
				font-size: 2rem;
			}
		}
		&__down-icon {
			background-image: url(../img/banner-down.svg);
			background-size: cover;
			bottom: 160px;
			height: 40px;
			left: 50%;
			position: absolute;
			transform: translateX(-50%);
			width: 40px;
			@include breakpoint(medium) {
				height: 60px;
				width: 60px;
			}
		}
	}