/* - BASE HTML TEMPLATE
------------------------------------------------- 
	Description: Functions
	Author:Shane Prendergast
	Author URL: http://www.webknit.co.uk
	Template URL: http://base.webknit.co.uk/
*/


/* - CONTENTS
-------------------------------------------------
	- Functions  ........................... 1.0.
*/


/*********************************************
	- 1.0 -  FUNCTIONS
*********************************************/
